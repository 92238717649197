import React, {useState, useEffect, useRef} from 'react'
import arrowLeft from '../../assets/arrow-left.svg'
import {useNavigate, useParams} from 'react-router-dom'
import { Form, Button, Image, Card, Overlay, Popover, Row, Col,ListGroup, Dropdown, DropdownDivider } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useAppContext } from '../../AppContext'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import deleteIcon from '../../assets/delete.svg'
import { FaTrash} from 'react-icons/fa';
import { faChevronDown, faChevronUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import orderRoute from '../../services/orderRoute';
import { debounce } from 'lodash';
import search from '../../assets/search.svg'
import { getParsedLocalStorageItem } from './AdminProfile';
import productRoute from '../../services/productRoute';

const OrderModification = () => {
    const { showToast } = useAppContext();
    const { orderId } = useParams();
    const navigate = useNavigate();
   
  const [orderDetail, setOrderDetail] = useState({});
  const [globalProducts, setGlobalProducts] = useState([]);
  const [searchProduct, setSearchProduct] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newlyAddedItems, setNewlyAddedItems] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [initialQuantities, setInitialQuantities] = useState({});
  const [orderLoading, setOrderLoading] = useState(false);
  const targetRef = useRef(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { getGlobalProducts } = productRoute();
  const [totalAmount, setTotalAmount] = useState(0);
  const [hasModifications, setHasModifications] = useState(false);
  const userData = getParsedLocalStorageItem('userData')
 
    
    const {getAdminSingleOrder,orderModify}= orderRoute();
 

    useEffect(()=> {
      getAdminSingleOrder (
        orderId, setOrderLoading,showToast,  (order) => {
            setOrderDetail(order);
            const quantities = {};
            order?.cart.forEach(item => {
              quantities[item?.productId?.id || item?.box?.id] = item.quantity;
            });
            setInitialQuantities(quantities);
          });
      
    },[])
  

    useEffect(() => {
        // Define a debounced version of the getProducts function
        const debouncedGetProducts = debounce((searchProduct) => {
          getGlobalProducts(
            setLoading,
            showToast,
            setGlobalProducts,
            searchProduct,
        );
        }, 500);
    
        if (searchProduct !== '') {
          setSearchPerformed(true)
            debouncedGetProducts(searchProduct);
        } else {
          
            getGlobalProducts(
                setLoading,
                showToast,
                setGlobalProducts,
            );
        }
    
        // Cleanup function to cancel the debounced function
        return () => {
            debouncedGetProducts.cancel();
        };
    }, [searchProduct]);
    
    useEffect(() => {
      // Calculate total amount whenever newlyAddedItems or orderDetail.cart changes
      let total = 0;
    
      // Calculate total from newlyAddedItems
      newlyAddedItems.forEach(item => {
        total += item?.quantity * item?.productId?.price; // Use item.productId.price instead of item.price
      });
    
      // Calculate total from orderDetail.cart
      orderDetail?.cart?.forEach(item => {
        const initialQuantity = initialQuantities[item?.productId?.id || item?.box?.id];
        if (item?.quantity > initialQuantity) {
          total += (item?.quantity - initialQuantity) * (item?.productId?.price || item?.box?.amount); // Use item.productId.price instead of item.price
        }
      });
    
      setTotalAmount(total);
      const hasChanges = newlyAddedItems.length > 0 || orderDetail?.cart?.some(item => item?.quantity > initialQuantities[item?.productId?.id || item?.box?.id]);
      setHasModifications(hasChanges);
    }, [newlyAddedItems, orderDetail?.cart, initialQuantities]);
    
  
      
      const togglePopover = () => {
        setIsPopoverVisible(prev => !prev);
      };
    
      const hidePopover = () => setIsPopoverVisible(false);
    
      const isProductChecked = (productId) => {
        return newlyAddedItems?.some(item => (item?.productId?.id || item?.box?.id) === productId) ||
               orderDetail?.cart?.some(item => (item?.productId?.id || item?.box?.id) === productId);
      };
    
      const getProductQuantity = (productId) => {
        const product = newlyAddedItems.find(item => (item?.productId?.id || item?.box?.id) === productId) ||
                        orderDetail?.cart?.find(item => (item?.productId?.id || item?.box?.id) === productId);
        return product ? product.quantity : 0;
      };
    
      const handleToggleItem = (productId) => {
        const product = globalProducts.find(p => p?.id === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
        if (product.stockLevel === 'out_of_stock' || product.quantityAvailable === 0) {
          showToast({ toastText: 'This product is out of stock.', isSuccess: false });
          return;
        }
    
        const existingItemIndex = orderDetail?.cart?.findIndex(item => (item?.productId?.id || item?.box?.id) === productId);
        const newItemIndex = newlyAddedItems.findIndex(item => (item?.productId?.id || item?.box?.id) === productId);
    
        if (newItemIndex !== -1) {
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems.splice(newItemIndex, 1);
          setNewlyAddedItems(updatedNewItems);
         
        } else if (existingItemIndex !== -1) {
          const updatedOrderCart = [...orderDetail?.cart];
          updatedOrderCart.splice(existingItemIndex, 1);
          setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
        } else {
          const newItem = {
            productId: product,
            quantity: 1,
            price: product.price
          };
          setNewlyAddedItems([...newlyAddedItems, newItem]);
          
        }
      };
    
      const handleChangeQuantity = (productId, delta) => {
        const product = globalProducts.find(p => p.id === productId);
        if (!product) {
          showToast({ toastText: 'Product not found.', isSuccess: false });
          return;
        }
    
        const existingItemIndex = orderDetail?.cart?.findIndex(item => (item?.productId?.id || item?.box?.id) === productId);
        const newItemIndex = newlyAddedItems.findIndex(item => (item?.productId?.id || item?.box?.id) === productId);
    
        if (existingItemIndex !== -1) {
          const item = orderDetail?.cart[existingItemIndex];
          const initialQuantity = initialQuantities[(item?.productId?.id || item?.box?.id)];
          const newQuantity = item.quantity + delta;
    
          if (newQuantity < initialQuantity) {
            showToast({ toastText: 'Modified quantity cannot be less than initial quantity.', isSuccess: false });
            return;
          }
    
          if (newQuantity > product.quantityAvailable) {
            if( product.quantityAvailable === 0){
              showToast({ toastText: 'This product is out of stock.', isSuccess: false });
            }else{
            showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
            return;
          }
    
          const updatedOrderCart = [...orderDetail?.cart];
          updatedOrderCart[existingItemIndex] = { ...item, quantity: newQuantity };
          setOrderDetail(prevDetail => ({ ...prevDetail, cart: updatedOrderCart }));
          const eventAction = delta > 0 ? 'increased' : 'decreased';
       
        } else if (newItemIndex !== -1) {
          const item = newlyAddedItems[newItemIndex];
          const newQuantity = item.quantity + delta;
    
          if (newQuantity <= 0) {
            const updatedNewItems = [...newlyAddedItems];
            updatedNewItems.splice(newItemIndex, 1);
            setNewlyAddedItems(updatedNewItems);
           
      
            return;
          }
    
          if (newQuantity > product.quantityAvailable) {
            if( product.quantityAvailable === 0){
              showToast({ toastText: 'This product is out of stock.', isSuccess: false });
            }else{
            showToast({ toastText: `We only have ${product.quantityAvailable} items in stock.`, isSuccess: false });}
            return;
          }
    
          const updatedNewItems = [...newlyAddedItems];
          updatedNewItems[newItemIndex] = { ...item, quantity: newQuantity };
          setNewlyAddedItems(updatedNewItems);
          const eventAction = delta > 0 ? 'increased' : 'decreased';
          
        }
      };

      const handlePay = () => {
        const modifiedCart = orderDetail?.cart
          .filter(item => item.quantity > initialQuantities[(item?.productId?.id || item?.box?.id)])
          .map(item => ({
            productId: (item?.productId?.id || item?.box?.id),
            quantity: item.quantity - initialQuantities[(item?.productId?.id || item?.box?.id)],
          }));
      
        const newItems = newlyAddedItems.map(item => ({
          productId: item?.productId?.id || item?.box?.id,
          quantity: item.quantity,
        }));
      
        const finalCart = [...modifiedCart, ...newItems];
      
        // Prepare the body object to pass to orderModify function
        const body = {
          action: "edit",
          newOrder: {
            cart: finalCart,
            paymentMethod: "Direct Transfer",
          }
        };
      
        
        orderModify(orderId, body, setLoading, showToast);
      };
      
    
    
      

     
 
  const handleBack = () => {
   
    navigate(-1);
  }


  return (
    <div>
        <div className='d-flex  justify-content-between align items-center header-admin  py-4 pe-5 mb-4 ps-4'>
        <div className='d-flex gap-3 align-items-center'>
            <img src={arrowLeft} onClick={handleBack} alt='arrow' />
        <h3 style={{color:'#344054'}} className='my-auto'>Modify Order</h3>
        </div>
      
        </div>
        <div className=' pe-5 ps-4 '>
        {orderLoading ? 
  (<div className='justify-content-center align-items-center text-center' style={{padding:'100px'}}>
  <div className="spinner-border text-success" role="status">
  <span className="visually-hidden">Loading...</span>
  </div>
  </div>):    <div>
 
          
          <Card style={{backgroundColor: '#469B1205',border:'none'}} className='py-3 h-100'>
            <div className='d-flex justify-content-between px-4 mt-1 mb-3 '>
                <h5 style={{color:'#344054', fontWeight:'600'}}>Item Summary</h5>
              
              </div>
          <Row style={{borderBottom:'1px solid #E1E6EF'}} className='px-2 mb-2 mx-2 ' >
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Image</h6>
          </Col>
          <Col xs={4}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Product Name</h6>
          </Col>
         
          <Col xs={1}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>QTY</h6>
          </Col>
        
          <Col xs={2}>
            <h6 style={{color:'#353542', fontWeight:'500'}}>Price </h6>
          </Col>
        </Row>
        <div className='d-flex flex-column gap-4 mt-2 pb-2'>
        {orderDetail?.cart?.map((item)=> (
        <Row  key={item?._id} className='mx-2 pb-1 mt-0 pt-0' style={{borderBottom:'1px solid #E1E6EF'}}>
           <Col xs={2} className='mt-0 pt-0'>
          <img 
        src={item?.productId?.productImage || item?.box?.boxImage} 
        alt={item?.productId?.productName || item?.box?.name} 
        style={{ width: "70px", height: "70px" }} 
      />
          </Col>
         
          <Col xs={4}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>{item?.productId?.productName || item?.box?.name}</h6>
          </Col>
         
          <Col xs={1}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4 ms-2'>{initialQuantities[item?.productId?.id || item?.box?.id]}</h6>
          </Col>
         
          <Col xs={2}>
          <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>
  {item?.productId?.price.toLocaleString() || item?.box?.amount.toLocaleString()}
</h6>

          </Col>

          <Col xs={4} md={3} className='d-flex gap-3 align-items-center'>
                            <div className="btn-group btn-group-sm gap-1" >
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, -1)}>-</Button>
      <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
      <Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, 1)}>+</Button>
      
      </div>
      
                            </Col>
        </Row>
        ))}

{newlyAddedItems.map((item) => (
   <Row  key={item?._id} className='mx-2 pb-1 mt-0 pt-0' style={{borderBottom:'1px solid #E1E6EF'}}>
   <Col xs={2} className='mt-0 pt-0'>
  <img 
src={item?.productId?.productImage} 
alt={item?.productId?.productName} 
style={{ width: "70px", height: "70px" }} 
/>
  </Col>
 
  <Col xs={4}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>{item?.productId?.productName}</h6>
  </Col>
 
  <Col xs={1}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4 ms-2'>{item?.quantity}</h6>
  </Col>
 
  <Col xs={2}>
  <h6 style={{color:'#344054', fontWeight:'400'}} className='my-4'>
{item?.productId?.price.toLocaleString()}
</h6>

  </Col>

  <Col xs={4} md={3} className='d-flex gap-3 align-items-center'>
  <div className="btn-group btn-group-sm gap-1" >
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{borderRadius:'3px', borderColor:'#B2BCCA',width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, -1)}>-</Button>
<Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{width:'20px', height:'30px', fontWeight:'600'}}>{item.quantity}</Button>
<Button variant='outline-secondary' className='px-1 py-0 d-flex text-center align-items-center justify-content-center'  style={{borderRadius:'3px', borderColor:'#B2BCCA', width:'30px', height:'30px'}} onClick={() => handleChangeQuantity(item.productId.id, 1)}>+</Button>

</div>
<img src={deleteIcon} alt='trash' onClick={() => handleToggleItem(item.productId.id)} style={{cursor:'pointer'}}/>

                    </Col>
</Row>
))}
        </div>
      
        </Card>
        <div className='my-3'>
                  <p style={{fontWeight:'500'}}>Add More Items</p>
                  <Col xs={12} md={7}>
                  <div onClick={togglePopover} ref={targetRef} style={{ cursor: 'pointer', border: '1px solid #ced4da', borderRadius: '8px', padding: '0.35rem 0.75rem', minHeight: '38px', gap:'5px',  }} className='d-flex align-items-center justify-content-between'>
                
   <div className='item-container'>
        {newlyAddedItems?.length > 0 ? (
          newlyAddedItems?.map((item) => (
            <Button variant='outline-secondary' style={{color:'#11142D', border:'1px solid #E7E8EA', fontWeight:'500',fontSize:'11px', boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.03)'}} key={item.productId.id} onClick={(e) => {
              e.stopPropagation(); // Prevent popover from toggling
              handleToggleItem(item.productId.id);
            }}>
              {item.productId?.productName} ({item.quantity}) &nbsp; X
            </Button>
          ))
        ) : (
          <span className="text-muted">Select additional items to add to your order.</span>
        )}
        </div>
        <div className='d-flex justify-content-end'>
        <i className="bi bi-chevron-down"></i>
        </div>
                  </div>
                  </Col>
                  <Overlay
    show={isPopoverVisible}
    target={targetRef.current}
    placement="bottom"
    rootClose={true}
    onHide={hidePopover}
  >
 
                    <Popover className='custom-popover' style={{ width: '430px' }}>
                      <Popover.Body>
                        <div className='d-flex align-items-center position-relative py-1 px-1 mb-3'>
                          {searchProduct !== '' && (
                            <i className='bi bi-x' style={{ top: '5%', right: '1.5%', position: 'absolute', fontSize: '1.5rem', color: '#98A2B3' }} onClick={() => setSearchProduct('')}></i>
                          )}
                          <Form.Control
                            style={{ border: '1px solid #E1E6EF', backgroundColor: '#E1E6EF', borderRadius: '3px', fontWeight: '500' }}
                            placeholder='Search by product name'
                            value={searchProduct}
                            onChange={(e) => setSearchProduct(e.target.value)}
                            autoFocus
                          />
                        </div>
                        {loading ? (
                          <div className='justify-content-center align-items-center text-center' style={{ padding: '150px' }}>
                            <div className="spinner-border text-success" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        ) : (
                          globalProducts.length > 0 ? (
                            <>
                              <div style={{ borderTop: '1px solid #E1E6EF' }} className='pb-2 pt-3'>
                                <h5 className='' style={{ fontWeight: '500' }}>Items</h5>
                              </div>
                              <ListGroup style={{ maxHeight: '300px', overflowY: 'scroll', border: 'none' }} className='no-border-listgroup'>
                                {globalProducts.map((product) => (
                                  <ListGroup.Item key={product.id} action className='d-flex justify-content-between'>
                                    <Form.Check
                                      type="checkbox"
                                      label={`${product.productName} - ₦${product.price.toLocaleString()}`}
                                      checked={isProductChecked(product.id)}
                                    onChange={() => handleToggleItem(product.id)}
                                    />
                                    {isProductChecked(product?.id) && (
                                      <div className="btn-group btn-group-sm">
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }} onClick={() => handleChangeQuantity(product.id, -1)}>-</Button>
                                        <Button variant='white' className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}>{getProductQuantity(product.id)}</Button>
                                        <Button className='px-1 py-0 d-flex text-center align-items-center justify-content-center' style={{ width: '1.5rem', height: '30px', fontWeight: '600' }}  onClick={() => handleChangeQuantity(product.id, 1)}>+</Button>
                                      </div>
                                    )}
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : (
                            searchPerformed && globalProducts.length === 0 ? (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4 className='mb-2'>No products found</h4>
                                <Button onClick={() => setSearchProduct('')}>View all products</Button>
                              </div>
                            ) : (
                              <div className='fw-medium ' style={{ padding: '50px 0' }}>
                                <h4>You have no products</h4>
                              </div>
                            )
                          )
                        )}
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                  </div>
                  <div className=' me-lg-5'>
                    <div className='d-flex gap-4 justify-content-end'>
                  <h5 style={{color:'#4F4F4F', fontWeight:'600'}}>Total:</h5>
            <h5 style={{color:'#4F4F4F', fontWeight:'600'}}> {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(totalAmount)}</h5>
                  </div>
                  <div className='d-flex gap-5 justify-content-end my-3'>
                <Button className=''onClick={handlePay} disabled={!hasModifications} style={{backgroundColor: !hasModifications ? 'rgba(70, 155, 18, 0.2)': '#469B12 ', color:'#F6F6F6', boxShadow: '0px 4px 4px 0px #0E35BF40', width:'200px'
}}>Save</Button>
                </div> 
                </div> 
        </div>
}
</div>
    </div>
  )
}

export default OrderModification

